// Override Bootstrap theming
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1250px
);
$grid-gutter-width: 16px;

$theme-colors: (
  'danger': #D92121
);

$body-bg: #111;
$body-color: #F4F4F4;

$font-family-sans-serif: 'MBCorpoSText-Cond' !default;
$headings-font-family: 'MBCorpoATitleCond';
$link-font-family: 'MBCorpoSText';

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2.1;
$h2-font-size: $h1-font-size; // h2 headlines are the same size as h1



// Custom

$highlight-color: #008DFC;

$sidenav-background-color: #000000;
$sidenav-background-active-color: #1A1A1A;
$sidenav-background-hover-color: #0D0D0D;

$content-block-background: #333333;

$form-error-color: map-get($theme-colors, 'danger');
$success-color: #21A330;

$footer-font-color: #BBBBBB;
$input-placeholder-color: #9F9F9F;
$input-background-color: #4F4F4F;
$input-search-background-color: #707070;
$input-disabled-background-color: #424242;
$dropdown-selected-option-color: #969696;
$tooltip-background-color: #262626;


$table-section-font-size: 1rem;
$table-content-font-size: 1rem;

$input-font-size: 1.125rem;
$footer-font-size: 0.875rem;

// Enable shadow on MatTab
$enable-shadows: true;
