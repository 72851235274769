.no-edit {
  cursor: not-allowed;
}

@media print {
  body.forms-print {
    zoom: 0.3;

    &, form, table, input, textarea, .footer, ::placeholder, textarea.main.form-control::placeholder, span:not(.title,.market), p, .table div {
      color: $sidenav-background-color !important;
      font-family: Verdana !important;
      font-size: 27px !important;
    }

    span.title {
      color: $sidenav-background-color !important;
      font-size: 30px !important;
    }

    td, th {
      border-top-color: $sidenav-background-color !important;
      position: static !important;
      font-size: 27px !important;
    }

    th {
      white-space: normal !important;
    }

    input::placeholder,
    textarea::placeholder {
      opacity: 0 !important;
    }

    .info-header-text {
      font-weight: bold !important;
    }

    .print {
      visibility: visible !important;
      height: unset !important;
    }

    .pie-chart-input-item {
      grid-template-columns: 150px auto 35px !important;

      span {
        margin-left: 2px !important;
        margin-top: 5px !important;
        margin-right: 4px !important;
      }

      input {
        padding-left: 35px !important;
        border-color: $sidenav-background-color !important;
      }
    }

    p.textarea {
      white-space: pre-wrap;
    }

    .print-border {
      border-top: 4px solid $sidenav-background-color !important;
    }

    .print-title {
      font-family: 'MBCorpoATitleCond' !important;
      font-size: 67px !important;
    }

    span.print-subtitle {
      font-size: 37px !important;
      font-family: 'MBCorpoSText-Cond' !important;
      font-weight: bold;
    }

    .info-header-text {
      margin-top: 16px !important;

      .content {
        margin: auto;
        font-size: 27px !important;

        img {
          height: 25px !important;
        }
      }
    }

    .print-form {
      margin-bottom: -60px !important;
      margin-right: 10px;

      .single-text-input {
        align-items: end !important;

        .body {
          span {
            font-size: 32px !important;
          }

          input {
            font-weight: bold !important;
            width: 185px !important;
            font-size: 32px !important;
          }
        }

        .footer {
          span {
            font-size: 24px !important;
          }
        }
      }
    }

    .form-instance-table {
      header {
        border-bottom: 4px solid $sidenav-background-color !important;
      }
    }

    .dealer-margin {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
      background-color: unset !important;
      padding-left: 0px !important;
      page-break-inside: avoid;

      .input-wrapper {
        background-color: #0000001a !important;
        height: 70px !important;
        padding: 0px !important;

        input {
          width: 100px !important;
        }

        > div {
          background-color: unset !important;
        }

        .label {
          height: 40px !important;
          margin-top: 10px !important;
        }
      }

      .print-span {
        background-color: #0000001a !important;
        padding: 10px;
      }

      .info-text {
        font-size: 27px !important;
        margin-left: -3px !important;
      }

      .header-title {
        font-size: 32px !important;
      }

      .rotated-section {
        bottom: 5% !important;
        display: flex !important;
        white-space: nowrap !important;

        .header-text {
          font-size: 32px !important;
          margin-top: 8px !important;
          font-weight: bold !important;
        }

        &.fixed {
          right: -100% !important;
          bottom: -5px !important;
        }
      }

      .group {
        &.info {
          > div {
            width: 90px !important;
            padding: 0px !important;
            border: none !important;
            background-color: white !important;
            color: $sidenav-background-color !important;

            > div {
              margin-left: 0px !important;
            }
          }
        }

        .box-0 {
          height: 1260px !important;

          .rotated-label, .rotated-value {
            color: $body-color !important;
            font-size: 22px !important;
            margin-left: auto !important;
            margin-right: auto !important;
          }

          .header-text, .info-text {
            font-size: 27px !important;
            color: $body-color !important;
          }

          .header-text {
            margin-left: -8px !important;
          }
        }

        .box-1 {
          height: 750px !important;

          .rotated-label, .rotated-value {
            color: $body-color !important;
            font-size: 22px !important;
            margin-left: auto !important;
            margin-right: auto !important;
          }

          .header-text, .info-text {
            font-size: 27px !important;
            color: $body-color !important;
          }

          .header-text {
            margin-left: -8px !important;
          }
        }

        .box-2 {
          height: 235px !important;

          .rotated-label {
            color: $body-color !important;
            font-size: 22px !important;
            margin-left: auto !important;
            margin-right: auto !important;
          }
        }
      }

      .bracket-container {
        margin-left: 5px !important;

        .long-bracket {
          height: 2050px !important;
          margin-top: 10px !important;
        }

        .short-bracket {
          height: 230px !important;
        }
      }
    }

    header, footer {
      background-color: unset !important;

      .error-section-icon {
        width: 30px;
        height: 24px;
        margin-right: 4px;
      }

      .error-section-text {
        font-size: 37px !important;
        font-family: 'MBCorpoSText-Cond' !important;
        margin-left: 4px;
      }
    }
  }
}
