@font-face {
  font-family: 'DaimlerCS';
  src: url('assets/fonts/DaimlerCS-Regular.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/DaimlerCS-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/DaimlerCS-Light.woff2') format('woff2'); /* Super Modern Browsers */
}
@font-face {
  font-family: 'DaimlerCS';
  font-weight: bold;
  src: url('./assets/fonts/DaimlerCS-Regular.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/DaimlerCS-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/DaimlerCS-Regular.woff2'); /* Super Modern Browsers */
}
@font-face {
  font-family: 'DaimlerCAC';
  src: url('./assets/fonts/DaimlerCS-Regular.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/DaimlerCS-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/DaimlerCAC-Regular.woff2'); /* Super Modern Browsers */
}

@font-face {
  font-family: 'MBCorpoAScreen1Cond';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/MBCorpoAScreen1Cond-Regular.woff2') format('woff2'),
       url('./assets/fonts/MBCorpoAScreen1Cond-Regular.woff') format('woff');
}

@font-face {
  font-family: 'MBCorpoAScreen2Cond';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/MBCorpoAScreen2Cond-Regular.woff2') format('woff2'),
       url('./assets/fonts/MBCorpoAScreen2Cond-Regular.woff') format('woff');
}

@font-face {
  font-family: 'MBCorpoATextCond';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/MBCorpoATextCond-Regular.woff2') format('woff2'),
       url('./assets/fonts/MBCorpoATextCond-Regular.woff') format('woff');
}

@font-face {
  font-family: 'MBCorpoATitleCond';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/MBCorpoATitleCond-Regular.woff2') format('woff2'),
       url('./assets/fonts/MBCorpoATitleCond-Regular.woff') format('woff');
}

@font-face {
  font-family: 'MBCorpoATitle';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/MBCorpoATitle-Regular.woff2') format('woff2'),
       url('./assets/fonts/MBCorpoATitle-Regular.woff') format('woff');
}

@font-face {
  font-family: 'MBCorpoSText';
  font-weight: normal;
  src: url('./assets/fonts/MBCorpoSText-Regular.woff2') format('woff2'),
       url('./assets/fonts/MBCorpoSText-Regular.woff') format('woff');
}
@font-face {
  font-family: 'MBCorpoSText';
  font-weight: lighter;
  src: url('./assets/fonts/MBCorpoSText-Light.woff2') format('woff2'),
       url('./assets/fonts/MBCorpoSText-Light.woff') format('woff');
}
@font-face {
  font-family: 'MBCorpoSText';
  font-weight: bold;
  src: url('./assets/fonts/MBCorpoSText-Bold.woff2') format('woff2'),
       url('./assets/fonts/MBCorpoSText-Bold.woff') format('woff');
}

@font-face {
  font-family: 'MBCorpoSText-Cond';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/MBCorpoSText-CondRegular.woff2') format('woff2'),
       url('./assets/fonts/MBCorpoSText-CondRegular.woff') format('woff');
}
@font-face {
  font-family: 'MBCorpoSText-Cond';
  font-weight: lighter;
  font-style: normal;
  src: url('./assets/fonts/MBCorpoSText-CondLight.woff2') format('woff2'),
       url('./assets/fonts/MBCorpoSText-CondLight.woff') format('woff');
}
@font-face {
  font-family: 'MBCorpoSText-Cond';
  font-weight: bold;
  font-style: normal;
  src: url('./assets/fonts/MBCorpoSText-CondBold.woff2') format('woff2'),
       url('./assets/fonts/MBCorpoSText-CondBold.woff') format('woff');
}


@font-face {
  font-family: 'MBCorpoSTitle';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/MBCorpoSTitle-Regular.woff2') format('woff2'),
       url('./assets/fonts/MBCorpoSTitle-Regular.woff') format('woff');
}
@font-face {
  font-family: 'MBCorpoSTitle';
  font-weight: lighter;
  font-style: normal;
  src: url('./assets/fonts/MBCorpoSTitle-Light.woff2') format('woff2'),
       url('./assets/fonts/MBCorpoSTitle-Light.woff') format('woff');
}
@font-face {
  font-family: 'MBCorpoSTitle';
  font-weight: bold;
  font-style: normal;
  src: url('./assets/fonts/MBCorpoSTitle-Bold.woff2') format('woff2'),
       url('./assets/fonts/MBCorpoSTitle-Bold.woff') format('woff');
}

@font-face {
  font-family: 'MBCorpoSTitle-Cond';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/MBCorpoSTitle-CondRegular.woff2') format('woff2'),
       url('./assets/fonts/MBCorpoSTitle-CondRegular.woff') format('woff');
}
@font-face {
  font-family: 'MBCorpoSTitle-Cond';
  font-weight: lighter;
  font-style: normal;
  src: url('./assets/fonts/MBCorpoSTitle-CondLight.woff2') format('woff2'),
       url('./assets/fonts/MBCorpoSTitle-CondLight.woff') format('woff');
}
@font-face {
  font-family: 'MBCorpoSTitle-Cond';
  font-weight: bold;
  font-style: normal;
  src: url('./assets/fonts/MBCorpoSTitle-CondBold.woff2') format('woff2'),
       url('./assets/fonts/MBCorpoSTitle-CondBold.woff') format('woff');
}
