
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$daimler-dep-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$daimler-dep-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$daimler-dep-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$daimler-dep-theme: mat.m2-define-light-theme((
  color: (
    primary: $daimler-dep-primary,
    accent: $daimler-dep-accent,
    warn: $daimler-dep-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($daimler-dep-theme);
@import '../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';

@import 'variables';
@import 'fonts';
@import '../node_modules/intl-tel-input/build/css/intlTelInput.min.css'; // Needed for country flags, see `.iti-flag` below.
@import '../node_modules/@ng-select/ng-select/themes/default.theme.css';

// Used for locale selection (footer).
.iti-flag {background-image: url('assets/icons/flags.png');}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti-flag {background-image: url('assets/icons/flags@2x.png');}
}

html {
  overflow-y: scroll;

  &.no-scroll {
    overflow-y: hidden;
  }
}

// load bootstrap after setting up variables
@import 'node_modules/bootstrap/scss/bootstrap.scss';

body {
  background: linear-gradient(90deg, #151515, #181818) fixed;

  &.login-background {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('assets/login_background.jpg');

    @media (min-width: 1921px) {
      background-image: url('assets/login_background@2x.jpg');
    }
  }
}

p, table, .table {
  color: $body-color;
}

small {
  font-size: 0.7em;
}

.no-break {
  white-space: nowrap
}

.break-all {
  word-break: break-all;
}

a, a:not([href], .primary) {
  color: $body-color;
  cursor: pointer;

  &:hover {
    text-decoration: underline $highlight-color;
    color: $body-color;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    color: darkgray;
  }

  .underline {
    text-decoration: underline;
    cursor: pointer;
  }
}

a.primary {
  font-weight: bold;
  font-family: $link-font-family;
  color: $highlight-color;
  text-decoration: none !important;

  &:hover {
    text-decoration: none !important;
    color: $highlight-color;
  }
}

hr {
  background-color: $input-background-color;
}

div {
  &.disabled {
    pointer-events: none;
    cursor: default;
    color: darkgray;
  }
}

hr {
  margin: 0px;
}

form, .form-group {
  .form-row-error {
    .label p {
      color: $form-error-color;
    }

    input {
      /* Copied from Bootstrap */
      border-bottom: 1px solid $form-error-color;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url('assets/icons/warning.svg');
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }

  .form-field-error {
    border-bottom: 1px solid $form-error-color !important;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('assets/icons/warning.svg');
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  input.form-field-error {
    background-position: right calc(0.375em + 0.1875rem) center;
  }

  textarea.form-field-error {
    background-position: right calc(0.375em + 0.1875rem) top calc(0.375em + 0.1875rem);
  }

  .form-field-error-message {
    font-size: 14px;
    font-weight: bold;
    font-family: 'MBCorpoSText';
  }

  input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: inset 0 0 0px 30px $input-search-background-color !important;
      -webkit-text-fill-color: $body-color;
      transition: none
    }

    &.force-white-bg:-webkit-autofill,
    &.force-white-bg:-webkit-autofill:hover,
    &.force-white-bg:-webkit-autofill:focus,
    &.force-white-bg:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px $input-background-color inset !important;
      box-shadow: 0 0 0 30px $input-background-color inset !important;
    }
  }
}

input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  background-image: url('assets/icons/close.svg');
  width: 14px;
  height: 14px;
  cursor: pointer;
}

#usercentrics-button {
  @media print {
    display: none;
  }
}

.loading {
  width: 30px;
  height: 30px;
  background: url('assets/loading_spinner.gif') no-repeat;

  &-white {
    background: url('assets/loading_spinner.gif') no-repeat;
  }

  &-form {
    /**
     * Reserved if styles of loading and loading-form
     * will differ in the future
     */
  }

  &-center {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.form-loading {
  width: 22px;
  height: 22px;
}

.table {
  &-admin {
    tr {
      td {
        border-top: 1px solid $input-background-color;
        padding-top: 9px;
        padding-bottom: 7px;
      }
    }

    tfoot tr td {
      padding-top: 12px;
    }
  }

  &-minheight {
    min-height: 100px;
  }

  &-order-icon {
    margin-right: 3px;
    text-decoration: none;
    font-weight: normal;
  }
}

input,
input.form-control,
input.form-control:focus,
input:focus,
select:focus,
select.form-control,
select.form-control:focus,
textarea.form-control,
textarea.form-control:focus,
textarea:focus
 {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none !important;
  background-color: $input-background-color;
  color: $body-color;
  border: none;
  font-size: $input-font-size;
  // Add a transparent border, so that the input size stays constant when an error occurs (`.form-row-error`).
  border-bottom: 1px solid transparent;
}

input[type=text].form-control,
input[type=search].form-control,
input[type=date].form-control,
input[type=email].form-control,
input[type=tel].form-control,
input[type=number].form-control,
input[type=time].form-control,
select.form-control {
  height: 40px;
  padding: 0.375rem 0.65rem;
}

input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid $input-placeholder-color;
  border-radius: 2px;
  width: 1em;
  height: 1em;
  background-color: inherit;
}

input[type=checkbox]:checked {
  -webkit-appearance: auto;
  -moz-appearance: auto;
}

input, select, textarea {
  &.form-control {
    border-radius: 0px;
    outline: 0;

    &:focus {
      outline: 0;
    }

    &:disabled, &[readonly] {
      background-color: $input-disabled-background-color;
    }

    &::placeholder {
      color: $input-placeholder-color !important;
      opacity: 1 !important; /* Firefox */
    }

    &:-ms-input-placeholder, &::-ms-input-placeholder { /* did not work combined with ::placeholder in Chrome */
      color: $input-placeholder-color !important;
    }
  }
}

.custom-select {
  color: #F4F4F4;
  height: 40px;
}

.alert {
  border-radius: 0px;

  &-primary {
    color: #175a6e;
    background-color: #dbf7ff;
    border-color: #95dbf0;
  }

  &-danger {
    color: #fff;
    background-color: rgba(255, 0, 0, 0.2);
    border-color: rgba(255, 0, 0, 0.8);
  }
}

button, a {
  &.btn:not(.btn-link,.btn-icon) {
    min-height: 48px;
    padding: 0px 24px;
  }

  &.btn {
    border-radius: 2px;
    box-shadow: none;
    font-size: 0.9375rem;
    font-family: $link-font-family;
    font-weight: bold;

    p {
      margin-bottom: 0px;
      color: $body-color;
    }

    &:focus, &:active:focus {
      box-shadow: unset !important;
    }

    &-sm {
      min-height: 40px !important;
      padding: 0px 20px !important;
    }

    &-primary {
      background-color: $highlight-color;
      color: $body-color;
    }

    &-primary:disabled {
      background-color: $highlight-color;
      opacity: 0.35;
      cursor: not-allowed;
    }

    &-primary:hover:enabled {
      background-color: #025497;
      border: 1px solid $highlight-color;
    }

    &-secondary {
      background-color: inherit;
      border: 1px solid $input-background-color;
    }

    &-secondary:disabled {
      cursor: not-allowed;
    }

    &-secondary:hover:enabled {
      background-color: #4F4F4F80;
    }

    &-search {
      width: 22px;
      height: 22px;
      background: url('assets/icons/search.png') no-repeat;
      border: none;
    }

    &-success {
      background-color: $success-color;
      border: none;
    }

    &-danger {
      background-color: $form-error-color;
      border: none;
    }

    &-small {
      height: 38px !important;
    }
  }

  &:focus {
    outline: 0;
  }
}

li:focus {
  outline: 0;
}

.content-block {
  background: $content-block-background;
  color: $body-color;

  h1, h2 {
    font-size: 2rem;
  }

  $content-padding: 20px;

  &-padding {
    padding: $content-padding;
    padding-top: 15px;
  }

  &-height {
    min-height: 555px;
    height: 100%;

    @include media-breakpoint-only(xs) {
      min-height: 100px;
    }
  }

  &-bar {
    margin: 0 #{ -$content-padding };
    padding: 8px $content-padding;
    background-color: $input-background-color;
  }

  &-controls{
    bottom: $content-padding;
    left: $content-padding;
    right: $content-padding;
    position: absolute;
    padding: 0px 10px;
  }

  &-with-buttons-bottom {
    padding-bottom: 50px;
  }

  &-shop {
    h1 {
      font-size: $font-size-base * 2;
    }

    h2 {
      font-size: $font-size-base * 1.25;
    }

    h3 {
      font-size: $font-size-base;
      font-weight: bold;
      font-family: $link-font-family;
    }
  }

  form {
    select, input:not([type=checkbox]), textarea {
      font-size: $input-font-size;
      background-color: $input-background-color;
    }

    input[type=search] {
      background-color: $input-search-background-color;
    }

    label {
      font-weight: bold;
    }
  }

  .loop {
    position: absolute;
    right: 43px;
    top: 0.6em;
  }
}

label {
  margin-bottom: 4px;
  font-weight: bold;
}

.table {
  div, td {
    font-size: $table-content-font-size;
  }

  strong {
    font-size: $table-content-font-size;
  }

  a {
    text-decoration: underline;
    text-underline-position: under;

    &:hover {
      text-decoration: underline;
      text-decoration-color: $highlight-color;
    }

    &.no-underline {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        text-decoration-color: $highlight-color;
      }
    }

    &.underline {
      text-decoration: underline;
    }
  }

  tr.new{
    color: $highlight-color;
  }


  tr:not(.new) {
    a {
      &:hover,
      &:active,
      &:focus {
        text-decoration-color: $highlight-color;
      }
    }
  }

  &.isLoading {
    opacity: 0.4;
  }
}

.menu-open-icon {
  position: absolute;
  top: 20px;
  right: 30px;
}

.sidenav-block {
  ul {
    font-family: 'MBCorpoSTitle';
    list-style-type: none;
    margin: 0;
    padding: 0;

    a {
      display: list-item;
      padding: 10px;
      padding-left: 1em;
      width: 100%;
      background: $sidenav-background-color;
      color: $body-color;
      font-size: 1.1rem;

      &.active {
        background: $sidenav-background-active-color;
        border: 6px solid $sidenav-background-color;
        padding-left: 12px;
      }

      &:hover {
        background: $sidenav-background-hover-color;
        cursor: pointer;
      }
    }
  }
}

.sidebar-block {
  background: $content-block-background;
  height: 100%;
  padding: 7px;

  p {
    font-size: 0.8em;
  }

  h2 {
    font-size: $font-size-base * 1.125;
    margin: 12px 0px;
  }

  img {
    width: 100%;
  }

  strong {
    color: $body-color !important;
    font-weight: normal !important;
  }
}

.modal {
  top: 25%;
  left: 50%;
  right: initial;
  bottom: initial;
  transform: translate(-50%, -25%);
  width: 100%;
  max-width: 700px;
  max-height: 100vh;
  overflow-y:  scroll;
  h2 {
    font-size: 31px;
  }

  &-backdrop.show {
    opacity: 0.8;
  }
}

.tabs {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  &-tab {
    display: inline-block;
    position: relative;
    width: 100%;
    min-height: 1px;
    flex: 0 0 25%;
    max-width: 25%;
    @include media-breakpoint-only(xs) {
      flex: 0 0 45%;
      max-width: 45%;
    }
    text-align: center;
    padding: 10px 10px;
    margin-right: 7px;
    color: $sidenav-background-color;
    background: #dfdfdf;
    cursor: pointer;

    &:hover {
      background: #eee;
    }
  }

  &-active, &-active:hover {
    background: #fff;
  }
}

.legal-page {
  white-space: normal;
}

.table-error-text {
  opacity: 0.5;
}

.badge {
  border-radius: 0px;
}

/* Tel./dial code fixes */
.iti {
  width: 100%;
}

.col-05 {
  flex: 0 0 4.1666666666%;
  max-width: 4.1666666666%;
}

.col-115 {
  flex: 0 0 95.8333333333%;
  max-width: 95.8333333333%;
}

@media (min-width: 576px) {
  .col-sm-115 {
    flex: 0 0 95.8333333333%;
    max-width: 95.8333333333%;
  }
}

.search-input {
  &-container {
    position: relative;
    width: 100%;
    padding-right: 30px;
    background: #707070;

    input {
      width: 100% !important;
    }
  }

  &-button {
    position: absolute;
    top: 10px;
    right: 9px;
    cursor: pointer;
  }
}

/**
 * Lists search bar
 */
$selectWidth: 31px;
input[name=searchTermInput] {
  width: calc(100% - #{$selectWidth});
  display: inline-block;
  position: relative;
}

select[name=searchFieldSelect] {
  appearance: none;
  vertical-align: bottom;
  width: $selectWidth;
  display: inline-block;
  background: rgba(255, 255, 255, 0.5);
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 0px;
  &::-ms-expand {
    display: none; //TODO: Works on IE10+; Add a workaround for other IE
  }
  & + .arrow{
    font-size: 12px;
    position: absolute;
    left: $selectWidth - 20px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

/**
 * Power BI iframe
 */
.dashboard-container > iframe {
  border: none !important;
}

.report-container > iframe {
  border: none !important;
}

/**
 * Temporary fix for weird distribution of flag-icon-css, which puts both 1:1
 * and 4:3 in the root folder (= 1:1 versions being shown).
 */
.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.3333333333em;
  line-height: 1em;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;

  &-de {
    background-image: url('assets/flags/de.svg');
  }
  &-us {
    background-image: url('assets/flags/us.svg');
  }
  &-it {
    background-image: url('assets/flags/it.svg');
  }
  &-es {
    background-image: url('assets/flags/es.svg');
  }
  &-fr {
    background-image: url('assets/flags/fr.svg');
  }
  &-nl {
    background-image: url('assets/flags/nl.svg');
  }
  &-pt {
    background-image: url('assets/flags/pt.svg');
  }
  &-cn {
    background-image: url('assets/flags/cn.svg');
  }
  &-kr {
    background-image: url('assets/flags/kr.svg');
  }
  &-th {
    background-image: url('assets/flags/th.svg');
  }
  &-vn {
    background-image: url('assets/flags/vn.svg');
  }
  &-jp {
    background-image: url('assets/flags/jp.svg');
  }
  &-ru {
    background-image: url('assets/flags/ru.svg');
  }
}

/**
 * Side-note styling in shop
 */
.text-sidenote {
  white-space: pre-wrap;
  text-align: justify;
}

/**
 * Privacy statement modal content
 */
.privacy-statement {
  h4 {
    &:not(:first-of-type) {
      margin-top: 30px;
    }
  }

  li {
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }

    & > span {
      display: inline-block;
      margin-bottom: 10px;
    }
  }
}

/* Border Color */
.ng-select.ng-invalid.ng-touched .ng-select-container {
  border-bottom: 1px solid $form-error-color;
}
/* Placeholder Color */
.ng-select.ng-invalid.ng-touched .ng-select-container .ng-placeholder {
  color: $form-error-color;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 7px
}

.ng-select {
  padding-right: 0px;
  padding-left: 0px;

  &.ng-select-opened.ng-select-bottom>.ng-select-container {
    background-color: $input-background-color;
    border: 1px solid $highlight-color;
  }

  &.ng-select-disabled>.ng-select-container {
    background-color: $input-disabled-background-color;
    cursor: default !important;

    .ng-value-container .ng-input>input {
      cursor: default !important;
    }
  }

  &.ng-select-opened>.ng-select-container {
    background-color: $input-background-color;
  }

  div {
    color: $body-color;
    font-size: $input-font-size;
  }

  .ng-select-container  {
    border-radius: 0;
    height: 40px;
    min-height: 40px;
    background-color: $input-background-color;
    border: none;
    cursor: pointer !important;

    .ng-value-container .ng-input>input {
      margin: 0px;
      cursor: pointer !important;
    }
  }

  .ng-arrow-wrapper {
    height: 8px;
    width: 14px;
    background-image: url('assets/icons/arrow_down.svg');
    background-repeat: no-repeat;
    margin-right: 8px;

    .ng-arrow {
      display: none !important;
    }
  }

  .ng-clear-wrapper {
    width: 14px !important;
    height: 10px;
    margin-right: 8px;
    background-image: url('assets/icons/close.svg');
    background-repeat: no-repeat;
    background-size: contain;

    .ng-clear {
      display: none !important;
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label {
    font-weight: 400;
  }
}

.ng-select.light {
  &.ng-select-opened.ng-select-bottom > .ng-select-container {
    background-color: $input-search-background-color;
  }

  .ng-select-container {
    background-color: $input-search-background-color;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items {
    .ng-option {
      background-color: $input-search-background-color;
    }

    .ng-option.ng-option-marked {
      background-color: $highlight-color;
    }

    .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
      background-color: $dropdown-selected-option-color;
      color: $body-color;
    }
  }
}

.ng-select.ng-select-single {
  .ng-select-container {
    min-height: 40px;

    .ng-value-container {
      padding-top: 3px;

      .ng-value {
        padding-right: 5px;
      }
    }
  }
}

.ng-select.ng-select-multiple {
  .ng-select-container .ng-value-container {
    .ng-value {
      background-color: $highlight-color;
      color: $body-color;
      padding: 3px;
      border-radius: 2px;
      height: 28px;
      letter-spacing: 0.4px;

      .ng-value-icon.left {
        border: none;
      }
    }

    .ng-input>input {
      color: $body-color;
    }
  }
}

.ng-dropdown-panel {
  border: none;

  &.ng-select-bottom {
    border: none;
  }

  ::-webkit-scrollbar {
    width: 5px;
    background-color: $input-background-color;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 100px;
    background-color: $content-block-background;
  }

  .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: $highlight-color;
    color: $body-color;
  }

  .ng-dropdown-panel-items .ng-option {
    background-color: $input-background-color;
    color: $body-color;
    border: none;
  }

  .ng-dropdown-panel-items .ng-optgroup {
    background-color: $input-background-color;
    font-weight: bold;
    color: $body-color;
  }

  .ng-dropdown-header {
    background-color: $input-background-color;
  }

  .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    background-color: $dropdown-selected-option-color !important;
    color: $body-color !important;
  }
}

.note-editable {
  max-height: 500px;
  overflow-y: auto;
}

.note-placeholder {
  padding: 0px !important;
  margin-top: -3px;
}

app-shop p {
  line-height: 1.3em;
  color: $footer-font-color;

  @media print {
    color: $sidenav-background-color;
  }
}

.note-modal-footer {
  height: 3.5rem;
}

.mb-paper {
  background-color: rgb(26, 26, 26);
  color: rgb(255, 255, 255);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08));
}

.mdc-form-field, .mdc-label {
  font-family: $font-family-sans-serif !important;
}

// Overwrite the default styling that is being applied to the Material slide toggle component.
// TODO: Could we move this / parts of this to an Angular Material theme?
.mdc-label {
  color: $body-color;

  --mat-slide-toggle-label-text-size: 16px;
}

.mdc-switch:disabled + .mdc-label {
  color: #9F9F9F;
}

.mdc-switch__track {
  background-color: $input-placeholder-color;
  border-radius: 12px !important;
  width: 34px !important;
  height: 19px !important;
}

.mdc-switch:enabled .mdc-switch__track::before {
  background: #9e9e9e !important;
}

.mdc-switch:enabled .mdc-switch__track::after {
  background: #008cfb !important;
}

.mdc-switch:disabled .mdc-switch__track::before {
  background: #7c7c7c !important;
}

.mdc-switch__handle {
  background: #f9f9f9 !important;
  border-radius: 10px !important;
  width: 14px !important;
  height: 14px !important;
}

.mdc-switch:disabled .mdc-switch__handle, .mdc-switch:disabled .mdc-switch__handle::before {
  background: #696969 !important;
}

.mdc-switch__handle {
  margin-left: 2px;
}

.mdc-switch__handle::after {
  background: none !important;
}

.mdc-switch__ripple, .mdc-switch__icons {
  display: none !important;
}

.mdc-form-field label {
  margin-bottom: 0px !important;
}

.mat-mdc-tooltip {
  background-color: #262626;
}

.mat-date-range-input-inner {
  &::placeholder {
    color: $input-placeholder-color !important;
    opacity: 1 !important; /* Firefox */
  }

  &:-ms-input-placeholder, &::-ms-input-placeholder { /* did not work combined with ::placeholder in Chrome */
    color: $input-placeholder-color !important;
  }
}

.mat-date-range-input-separator {
  color: #fff !important;
}

// Stage Wall Content
.stage-wall {

  // Overwrite the default styling that is being applied to the Material tab group.
  .mat-mdc-tab-body-content {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow: hidden;
  }

  .mat-mdc-tab-body-content::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  .mat-mdc-tab-header {
    border-bottom: 1px solid rgb(0, 0, 0, 0.12);
  }

  .mat-mdc-tab-header-pagination-chevron {
    border-color: #008DFC;
  }

  .mat-mdc-tab-labels {
    border-bottom: 1px solid #E8E8E8;
  }

  .mdc-tab__text-label {
    font-size: 1.125rem;
    font-weight: bold;
    padding: 0px 0px;
    font-family: "MBCorpoSText-Cond";
    color: #E8E8E8 !important;
    justify-content: center;
    letter-spacing: normal;
  }

  .mdc-tab-indicator__content--underline {
    border-color: #008DFC !important;
    margin-bottom: -1px;
    border-top-width: 3px !important;
  }
}

// ngx-toastr
.toast-container .ngx-toastr {
  border-radius: 4px;
  box-shadow: none;
}

/* Custom styles for mat-form-field */
mat-form-field.form-instance-list,
mat-form-field.custom {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: $input-background-color;
  }

  .mat-datepicker-input {
    height: 40px;
    width: 100%;
    font-family: 'MBCorpoSText-Cond';
    padding: 6px 10px;
    font-size: 18px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field {
    border-radius: 0px;
    padding: 0px;
  }

  .mat-mdc-form-field-flex {
    height: 40px;
  }

  .mat-mdc-form-field-infix {
    padding: 0px;
  }

  mat-datepicker-toggle, .mdc-text-field--filled .mdc-text-field__input {
    color: $body-color;
    caret-color: $body-color;
    background-color: #4F4F4F !important;
    height: 40px !important;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }

  .mdc-line-ripple::after,
  .mdc-line-ripple::before {
    border-bottom-width: 0 !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    height: 0px;
  }
}

/* Custom styles for mat-date-range-input */
mat-date-range-input {
  background: $input-search-background-color !important;
  border: none !important;
  color: $body-color !important;
  border-radius: 0 !important;

  input {
    color: $body-color;
    background-color: $input-search-background-color !important;
  }
}

/* Custom styles for mat-datepicker-toggle */
mat-datepicker-toggle {
  background-color: $input-search-background-color !important;
  height: 41px !important;

  .calendar-icon {
    width: 16px !important;
    vertical-align: top !important;
    margin-top: -5px !important;
    margin-left: -6px !important;
  }

  /* Custom styles for mat-datepicker calendar */
  .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
    background-color: rgb(159, 159, 159) !important;
    opacity: 50% !important;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 35px;
    margin-bottom: 5px;
  }

  .mat-mdc-icon-button .mat-mdc-button-ripple, .mat-mdc-icon-button .mat-mdc-button-persistent-ripple, .mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
    top: 3px !important;
    right: 3px !important;
  }
}

/* Custom Styles for Custom Inputs Dropdown (Text + Select) */
.mat-mdc-autocomplete-panel {
  background-color: $input-background-color !important;
  padding: 0px !important;

  .mat-mdc-option {
    color: $body-color;
    font-size: 18px;
    font-family: 'MBCorpoSText-Cond';
    padding-left: 10px;
    letter-spacing: normal;
    min-height: 40px;

    &.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
      color: $highlight-color !important;
    }
  }
}

/* Removing mat-pseudo-checkbox icon from selected custom input */
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
  display: none;
}

/* Custom styles for JQuery time range slider */
.time-range-slider {
  .rs-tooltip-text {
    font-size: 12px;
    color: #008DFC;
  }

  .rs-border {
    border: none;
  }

  .rs-control {
    border: 1px solid #aaa;
    border-radius: 100%;
    padding: 4px;
  }

  .rs-range-color {
    background: radial-gradient(
      ellipse at bottom,
      rgba(255, 255, 255, 1) 0%,
      #008DFC 34%,
      #008DFC 66%,
      rgba(5, 0, 0, 1) 100%
    );
  }

  .rs-path-color {
    background: #707070;
  }

  .rs-bg-color {
    background: #4F4F4F;
    box-shadow: 0px 0px 10px 2px rgb(0, 0, 0, 15%)
  }

  .rs-bar .rs-seperator {
    display: none;
  }

  .rs-handle {
    padding: 1px;
    background-color: #4F4F4F;
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 40%);
  }

  span.rs-number {
    position: absolute;
    top: -8px;
    left: 30px;
    font-family: Segoe UI;
    font-weight: bold;
    font-size: 9px;
    color: #787878
  }

  .rs-bar.rs-start .rs-seperator,
  .rs-bar.rs-end .rs-seperator {
    display: none;
  }

  .rs-inner-container {
    left: 2px;
    top: -1px;
  }
}

@import 'app/support/styles';
@import 'app/forms/styles';
